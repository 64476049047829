import dynamic from 'next/dynamic'
import { Spinner } from '@stone-payments/jade'

import { Flex } from '~/domains/platform/design-system'
import { withPageConfig } from '~/domains/platform/infra/page-enhancers/with-page-config'

const Feature = dynamic(() => import('identity/auth/login').then(mod => mod), {
  loading: () => (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Spinner size="xlarge" />
    </Flex>
  ),
  ssr: false
})

export default withPageConfig(() => <Feature />, {
  deusExMachina: false,
  navigation: undefined,
  heimdallSetFeatureFlags: false
})
